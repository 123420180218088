import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";
import {
  BookingRequestInput,
  CreateBookingRequestMutationInputType,
  CreateBookingRequestMutationType,
  CREATE_BOOKING_REQUEST_MUTATION,
} from "~/lib/gql/create-booking-request.gql";
import {
  BookingInput,
  CreateBookingMutationInputType,
  CreateBookingMutationType,
  CREATE_BOOKING_MUTATION,
} from "~/lib/gql/create-booking.gql";
import { GET_BOOKINGS_FOR_USER_QUERY } from "~/lib/gql/get-bookings-for-user.gql";
import { useMe } from "~/hooks/useMe";
import { GET_COMMUNITY_BOOKINGS } from "~/components/community/hooks/get-community-bookings.gql";
import { TheDay } from "~/_libs/time/TheDay";

export const useCreateBooking = () => {
  const { userId } = useMe();
  const fromDate = TheDay.now().toString();

  const [
    createBooking,
    {
      data: bookingData,
      loading: bookingLoading,
      error: bookingError,
      reset: bookingReset,
    },
  ] = useMutation<CreateBookingMutationType, CreateBookingMutationInputType>(
    CREATE_BOOKING_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_BOOKINGS_FOR_USER_QUERY,
          variables: { userId: userId, fromDate: fromDate },
        },
        {
          query: GET_COMMUNITY_BOOKINGS,
          variables: { meId: userId, fromDate: fromDate },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const [
    createBookingRequest,
    {
      data: bookingRequestData,
      loading: bookingRequestLoading,
      error: bookingRequestError,
      reset: bookingRequestReset,
    },
  ] = useMutation<
    CreateBookingRequestMutationType,
    CreateBookingRequestMutationInputType
  >(CREATE_BOOKING_REQUEST_MUTATION, {
    refetchQueries: [
      {
        query: GET_BOOKINGS_FOR_USER_QUERY,
        variables: { userId: userId, fromDate: fromDate },
      },
      {
        query: GET_COMMUNITY_BOOKINGS,
        variables: { meId: userId, fromDate: fromDate },
      },
    ],
    awaitRefetchQueries: true,
  });

  const reset = useCallback(() => {
    bookingReset();
    bookingRequestReset();
  }, [bookingRequestReset, bookingReset]);

  return useMemo(() => {
    return {
      reset,
      data:
        bookingData?.createBooking ?? bookingRequestData?.createBookingRequest,
      error: bookingError ?? bookingRequestError,
      isLoading: bookingLoading || bookingRequestLoading,
      createNewBooking: async (booking: BookingInput) => {
        reset();
        await createBooking({
          variables: {
            booking,
          },
        });
      },
      createNewBookingRequest: async (bookingRequest: BookingRequestInput) => {
        reset();
        await createBookingRequest({
          variables: {
            bookingRequest,
          },
        });
      },
    };
  }, [
    bookingError,
    bookingRequestError,
    bookingData,
    bookingRequestData,
    bookingLoading,
    bookingRequestLoading,
    reset,
    createBooking,
    createBookingRequest,
  ]);
};
