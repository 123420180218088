import { subMinutes } from 'date-fns';
import { TheDay } from './TheDay';
import { TheTime } from './TheTime';
import { addHours, addMinutes } from 'date-fns';

export class TheDateTime {
  public readonly time: TheTime;
  public readonly date: TheDay;
  private readonly dateFormat: Date;

  constructor(date: TheDay, time: TheTime) {
    if (!date) {
      throw new Error(
        'Invalid TheDateTime constructor parameters - date is not set',
      );
    }

    if (!time) {
      this.time = TheTime.startOfDay();
    } else if (time.isEqual(new TheTime(24, 0))) {
      this.time = TheTime.endOfDay();
    } else {
      this.time = time;
    }

    this.date = date;
    this.dateFormat = new Date(
      `${this.date.toString()}T${this.time.toString()}:00.000Z`,
    );
  }

  static parse(date: string): TheDateTime {
    if (!date) {
      return null;
    }

    const result = new TheDateTime(TheDay.parse(date), TheTime.parse(date));
    return result;
  }

  static isDateOnly(date: string) {
    return !date.includes('T');
  }

  static fromDateAndTimeString(date: string, time: string): TheDateTime {
    if (!date || !time) {
      return null;
    }

    const parsedDate = TheDay.parse(date);
    const parsedTime = TheTime.parse(time);

    if (!parsedDate || !parsedTime) {
      throw new Error(`Could not parse either date or time: ${date} | ${time}`);
    }

    if (parsedTime.toString() === '24:00') {
      return new TheDateTime(parsedDate, TheTime.endOfDay());
    }

    return new TheDateTime(parsedDate, parsedTime);
  }

  static fromDateAndTime(date: TheDay, time: TheTime): TheDateTime {
    return new TheDateTime(date, time);
  }

  toDate(): Date {
    return this.dateFormat;
  }

  static now(): TheDateTime {
    const date = new Date();
    return new TheDateTime(TheDay.from(date), TheTime.from(date));
  }

  endOfDay(): TheDateTime {
    return new TheDateTime(this.date, TheTime.endOfDay());
  }

  isEqual(other: TheDateTime): boolean {
    if (!other) {
      return false;
    }

    return this.date.isEqual(other.date) && this.time.isEqual(other.time);
  }

  isBeforeNow(): boolean {
    const nowTime = TheTime.now();
    const nowDay = TheDay.now();

    return (
      this.date.isBeforeToday() ||
      (this.date.isEqual(nowDay) && nowTime.isAfter(this.time))
    );
  }

  isAfterNow(): boolean {
    return !this.isBeforeNow();
  }

  addHours(hours: number): TheDateTime {
    const date = this.toDate();
    const newDate = addHours(date, hours);
    return TheDateTime.parse(newDate.toISOString());
  }

  addMinutes(minutes: number): TheDateTime {
    const date = this.toDate();
    const newDate = addMinutes(date, minutes);
    return TheDateTime.parse(newDate.toISOString());
  }

  subMinutes(minutes: number): TheDateTime {
    const date = this.toDate();
    const newDate = subMinutes(date, minutes);
    return TheDateTime.parse(newDate.toISOString());
  }

  beginOfDay(): TheDateTime {
    return new TheDateTime(this.date, new TheTime(0, 0));
  }

  compare(other: TheDateTime): number {
    if (!other) {
      return 1;
    }

    if (this.date.isEqual(other.date)) {
      return this.time.compare(other.time);
    }

    return this.date.compare(other.date);
  }

  toString(): string {
    return `${this.date.toString()}T${this.time.toString()}:00.000Z`;
  }

  toTimeZoneFreeString(): string {
    return `${this.date.toString()}T${this.time.toString()}`;
  }
}
